import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/image.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["CarouselCore"] */ "/app/src/components/carousel-core.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/index.ts");
;
import(/* webpackMode: "eager" */ "/app/src/components/widget/container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/product-pointer-banner-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/home-hero-slider-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/homepage-five-images-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/landing-recommendation-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/reco-with-title-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/widgets/recommendation-tab-view-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/flatpage-featured-categories/item.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/flatpage-special-deals.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/home-slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/homepage-featured-categories/item.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/landing-multiple-slider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/landing-single-image.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/order-again.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/special-day.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/widgets/special-deals.tsx");
